@font-face {
  font-family: "Maax";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/maax-webfont.woff2") format("woff2"),
    url("../fonts/maax-webfont.woff") format("woff");
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: "Maax", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 5.5rem;
}

h1 > span {
  display: inline-block;
  float: left;
  margin-left: -2px;
  transform: translateY(9rem) translateX(-1rem) rotateZ(10deg);
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: .8s, 0.7s;
  transition-timing-function: cubic-bezier(0.27, 0.84, 0.09, 0.99) ease-in;
}

.font-loaded h1 > span {
  opacity: 1;
  transform: translateY(0);
}

.font-loaded h1 > span:nth-of-type(2) {
  transition-delay: 0.025s;
}
.font-loaded h1 > span:nth-of-type(3) {
  transition-delay: 0.05s;
}
.font-loaded h1 > span:nth-of-type(4) {
  transition-delay: 0.075s;
}
.font-loaded h1 > span:nth-of-type(5) {
  transition-delay: 0.1s;
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.detail {
  position: fixed;
  top: 20px;
  right: 20px;
  font-weight: normal;
  color: black;
  opacity: 0;
  transition: opacity 1s;
  z-index: 1;
}

.font-loaded .detail {
  opacity: 1;
}

.detail a {
  color: #e8242f;
  font-style: normal;
}

.github {
  top: auto;
  right: auto;
  bottom: 20px;
  left: 20px;
}

@media (min-width: 480px) {
  .detail {
    top: 50px;
    right: 50px;
  }

  .detail--right {
    top: 50%;
  }

  .github {
    top: auto;
    right: auto;
    bottom: 50px;
    left: 50px;
  }
}
